<template>
  <m-main-layout>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>字体大小</span>
          </div>
          <div class="f-12 mg-b-10">12px 样式名 .f-12 </div>
          <div class="f-14 mg-b-10">14px 样式名 .f-14</div>
          <div class="f-16">16px 样式名 .f-16</div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>文字颜色</span>
          </div>
          <div class="f-16 red-0 mg-b-10">红色 样式名 .red-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
          <div class="f-16 red-8 mg-b-10">红色 样式名 .red-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
          <div class="f-16 blue-0 mg-b-10">蓝色 样式名 .blue-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
          <div class="f-16 blue-8 mg-b-10">蓝色 样式名 .blue-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
          <div class="f-16 green-0 mg-b-10">绿色 样式名 .green-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
          <div class="f-16 green-5 mg-b-10">绿色 样式名 .green-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
          <div class="f-16 dark-0 mg-b-10">黑色 样式名 .dark-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
          <div class="f-16 dark-8 mg-b-10">黑色 样式名 .dark-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
          <div class="f-16 orange-0 mg-b-10">橙色 样式名 .orange-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
          <div class="f-16 orange-6 mg-b-10">橙色 样式名 .orange-* (* 为数字通配符，0-12，数字越大，颜色越浅)</div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>背景色</span>
          </div>
          <div class="f-16 mg-b-10 dark-0 bg-blue-3 pd-10">.bg-blue-* (* 为数字通配符，0-8，数字越大，颜色越浅)</div>
          <div class="f-16 mg-b-10 dark-0 bg-red-8 pd-10">.bg-red-* (* 为数字通配符，0-8，数字越大，颜色越浅)</div>
          <div class="f-16 mg-b-10 dark-0 bg-green-6 pd-10">.bg-green-* (* 为数字通配符，0-8，数字越大，颜色越浅)</div>
          <div class="f-16 mg-b-10 dark-0 bg-orange-8 pd-10">.bg-orange-* (* 为数字通配符，0-8，数字越大，颜色越浅)</div>
          <div class="f-16 mg-b-10 dark-0 bg-dark-8 pd-10">.bg-dark-* (* 为数字通配符，0-8，数字越大，颜色越浅)</div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>边框</span>
          </div>
          <div class="f-16 mg-b-10 dark-0 bd-w-5 bd-style-solid bd-blue-0 pd-10">边框大小 .bd-w-* (* 为数字通配符，0-10)</div>
          <div class="f-16 mg-b-10 dark-0 bd-w-5 bd-style-dashed bd-blue-0 pd-10">边框样式 .bd-style-* (* 为数字通配符，可选值dotted,solid,dashed,double,none,hidden，默认 solid)</div>
          <div class="f-16 mg-b-10 dark-0 bd-w-5 bd-style-solid bd-blue-6 pd-10">边框颜色 .bd-blue-* (* 为数字通配符，0-10)</div>
          <div class="f-16 mg-b-10 dark-0 bd-w-5 bd-style-solid bd-red-6 pd-10">边框颜色 .bd-red-* (* 为数字通配符，0-10)</div>
          <div class="f-16 mg-b-10 dark-0 bd-w-2 bd-style-solid bd-green-1 pd-10 bd-radius-10">圆角 .bd-radius-* (* 为数字通配符，0-30，步长 2)</div>
          <div class="f-16 mg-b-10 dark-0 bd-w-2 bd-style-solid bd-green-1 pd-10 bd-radius-4">圆角 .bd-radius-* (* 为数字通配符，0-30，步长 2)</div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>边距</span>
          </div>
          <div class="bd-w-1 bd-style-dashed bd-blue-0 pd-10">
            <div class="mg-b-10">.pd-10</div>
            <div class="mg-b-10">.pd-t-10</div>
            <div class="mg-b-10">.pd-b-10</div>
            <div class="mg-b-10">.pd-l-10</div>
            <div class="mg-b-10">.pd-r-10</div>
            <div class="mg-b-10">.pd-t-10</div>
            <div class="mg-b-10">.pd-b-10</div>
            <div class="mg-b-10">.pd-t-b-10</div>
            <div class="mg-b-10">.pd-l-r-10</div>
            <div class="mg-b-10">.mg-10</div>
            <div class="mg-b-10">.mg-t-10</div>
            <div class="mg-b-10">.mg-b-10</div>
            <div class="mg-b-10">.mg-l-10</div>
            <div class="mg-b-10">.mg-r-10</div>
            <div class="mg-b-10">.mg-t-10</div>
            <div class="mg-b-10">.mg-b-10</div>
            <div class="mg-b-10">.mg-t-b-10</div>
            <div class="mg-b-10">.mg-l-r-10</div>
          </div>
          <div class="bd-w-1 bd-style-dashed bd-blue-0 pd-10">
            .pd-10
            <div class="f-16 bd-w-1 mg-10 bd-style-dashed bd-blue-0">.mg-10</div>
          </div>
          <div class="f-16 bd-w-1 mg-t-10 bd-style-dashed bd-blue-0">.mg-t-10</div>
          <div class="f-16 bd-w-1 mg-b-10 bd-style-dashed bd-blue-0">.mg-b-10</div>
          <div class="f-16 bd-w-1 mg-l-10 bd-style-dashed bd-blue-0">.mg-l-10</div>
          <div class="f-16 bd-w-1 mg-r-10 bd-style-dashed bd-blue-0">.mg-r-10</div>
          <div class="f-16 bd-w-1 mg-l-r-50 bd-style-dashed bd-blue-0">.mg-l-r-50</div>
          <div class="f-16 bd-w-1 mg-t-b-50 bd-style-dashed bd-blue-0">.mg-t-b-50</div>
        </el-card>
      </el-col>
    </el-row>
  </m-main-layout>
</template>

<script>
export default {
  name: "css",
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>
